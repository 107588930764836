const state = {
  activateCategoryList: false,
  selectedCategoryModel: false,
  checkedCategoryList: [],
}

const getters = {
  getActivateCategoryList: (state) => {
    return state.activateCategoryList;
  },
  getCheckedCategoryList: (state) => {
    return state.checkedCategoryList;
  },
  getSelectedCategoryModel: (state) => {
    return state.selectedCategoryModel;
  },
}

const mutations = {
  setActivateCategoryList: (state, payload) => {
    state.activateCategoryList = payload;
  },
  addToCategoryList: (state, payload) => {
    state.checkedCategoryList.push(payload);
  },
  removeFromCategoryList: (state, payload) => {
    state.checkedCategoryList.splice(state.checkedCategoryList.indexOf(payload), 1);
  },
  updateCategories: (state, payload) => {
    state.checkedCategoryList.forEach((el)=> {
        el[payload.field] = payload[payload.field];
    })
    ;
  },
  setCheckedCategoryList: (state, list) => {
    state.checkedCategoryList = list;
  },
  setSelectedCategoryModel: (state, payload) => {
    state.selectedCategoryModel = payload;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
