import {cloneData} from "@/utils/form";

export default {
  addToBlockList: (state, payload) => {
    state.checkedBlocks.push(payload);
  },
  setBlockList: (state, payload) => {
    state.checkedBlocks = payload;
  },
  updateBlockList: (state, payload) => {
    for (const el of state.checkedBlocks) {
      el.categories = cloneData(payload);
    }
  }
}
