import {cloneData} from "@/utils/form";

export default {
  addToBlockPriceCategoryList: (state, payload) => {
    state.checkedBlockPriceCategories.push(payload);
  },
  removeFromBlockPriceCategoryCheckedList: (state, payload) => {
    state.checkedBlockPriceCategories.splice(state.checkedBlockPriceCategories.indexOf(payload), 1);
  },
  updateBlockPriceCategoryCheckedList: (state, payload) => {
    state.checkedBlockPriceCategories.forEach((el)=> {
      for (const key of Object.keys(payload)) {
        el[key] = cloneData(payload[key]);
      }
    })
    ;
  },
  setCheckedBlockPriceCategoryList: (state, list) => {
    state.checkedBlockPriceCategories = list;
  },
  setSelectedBlockPriceCategoryModel: (state, payload) => {
    state.selectedBlockPriceCategoryModel = payload;
  },
}
