import {isNotUndefinedAndNotNull} from "@/utils/classes";

export default {
  set: (state, payload) => {
    state.validations.push(payload);
    console.log(payload.name, 'looooh')
  },
  check: (state, payload) => {
    const violations = payload.data.violations;
    console.log(payload.config.url);
    console.log(state.validations);
    for (const violation of violations) {
      for (const validation of state.validations) {
        if (isNotUndefinedAndNotNull(validation) && isNotUndefinedAndNotNull(validation[violation.propertyPath])) {
         console.warn(validation[violation.propertyPath].id, payload.config.url);
          if (validation[violation.propertyPath].id === payload.config.url) {
            validation[violation.propertyPath].message = violation.message;
            validation[violation.propertyPath].valid = false;
          }
        }
      }
    }
    },
  clear: (state, payload) => {
    for (const key of Object.keys(payload)) {
      payload[key].message = true;
      payload[key].valid = true;
    }
  }
}
