const path = 'duplicate';

export default [
  {
    path: path,
    name: 'duplicate',
    component: () => import('@views/duplicate/Index'),
  },
  {
    path: path + '/validate',
    name: 'duplicate_shop_category',
    component: () => import('@views/duplicate/shop/CategoryShopIndex'),
  },
  {
    path: path + '/product/validate',
    name: 'duplicate_product_category',
    component: () => import('@views/duplicate/shop/product/ProductShopIndex'),
  },
  {
    path: path + '/calendar/validate',
    name: 'duplicate_calendar',
    component: () => import('@views/duplicate/calendar/CalendarIndex'),
  },
  {
    path: path + '/block-price-category/validate',
    name: 'duplicate_block_price_category',
    component: () => import('@views/duplicate/block-price-category/BlockPriceCategoryIndex'),
  },
  {
    path: path + '/block-price/validate',
    name: 'duplicate_block_price',
    component: () => import('@views/duplicate/block-price/BlockPriceIndex'),
  },
  {
    path: path + '/calendar/blocks/validate',
    name: 'duplicate_calendar_blocks',
    component: () => import('@views/duplicate/calendar/block/BlockIndex'),
  },
]
