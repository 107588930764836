import {_get, _put, _post} from "@api/services/httpService";
import { fromIriReferenceToId } from "@/utils/form";

const URL = 'clubs/';

export const getCurrentClub = () => _get(URL + fromIriReferenceToId('/clubs/', JSON.parse(localStorage.getItem('selectedClub'))));

export const putClub = (id, club) => _put(URL + id, club);

export const getParams = (id, dateString) => _get(URL + id + '/planning-parameter?date=' + dateString);

export const getAllClub = (page, itemPerPage, search = '') => {
    let url = '/clubs?itemsPerPage=' + itemPerPage + '&page=' + page;
    if (search !== '') {
        url += '&search=' + search;
    }
    return _get(url)
};

export const postClub = (club) => _post('/clubs', club);

export const getClub = (iri) => _get(iri);

export const getClubs = (page = 1, itemsPerPage = 8, search = '') => _get(URL + '?page=' + page + '&itemsPerPage=' + itemsPerPage + '&search=' + search);

export const getConfigMbClub = (clubId) => _get("/clubs/" + clubId + "/white-label");

export const addConfigMbClub = (data) => _post('/clubs/white-labels', data);

export const upConfigMbClub = (iri, data) => _put(iri, data);

export const postDuplicateClub = (data) => _post('/clubs/duplicate-configuration', data);
