export default {
  getCheckedBlockPrices: (state) => {
    return state.checkedBlockPrices;
  },
  getImpactedParks: (state) => {
    return state.impactedParks;
  },
  getSelectedBlockPriceModel: (state) => {
    return state.selectedBlockPriceModel;
  },
}
