<template>
  <div class="zoom-90" id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import Bus from "@/utils/bus";

export default {
  name: "app",
  beforeCreate() {
    this.$store.commit('layout/setWidth', window.innerWidth);
  },
  beforeDestroy() {
    try {
    Bus.$off('on:400-errors-handled');
    } catch (err) {
      console.error(err);
    }
  },
  mounted () {
    Bus.$on('on:400-errors-handled', (err) => {
      const data = err.response.data;
      if ("undefined" !== typeof data.violations) {
        this.$store.dispatch('validator/check', err.response);
      }
    })
    window.addEventListener('resize', () => {
      this.$store.commit('layout/setWidth', window.innerWidth);
    });
  },
};
</script>
<style>
.zoom-90{
  zoom: 0.9;
}

/deep/ .vs-checkbox--input:checked + .vs-checkbox .vs-icon {
  font-size: initial;
  font-family: 'Material Icons';
}
</style>
