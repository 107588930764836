import {cloneData} from "@/utils/form";
import {refine} from "@/utils/classes";

export default {
  addToBlockPricesList: (state, payload) => {
    const copyPayload = cloneData(payload);
    refine(copyPayload);

    copyPayload.pricePerParticipant !==null ? copyPayload.pricePerParticipant = parseFloat(copyPayload.pricePerParticipant/100).toFixed(2) : copyPayload.pricePerParticipant;
    copyPayload.duration !== null ? copyPayload.duration = parseFloat(copyPayload.duration / 60).toFixed(0) : copyPayload.duration;
    copyPayload.club = payload.club;
    copyPayload.participantCategories = [];

    copyPayload.nextoreProduct = payload.nextoreProduct ? {
      id: payload.nextoreProduct['@id'],
      accountSale: payload.nextoreProduct.accountSale,
      category: payload.nextoreProduct.category,
      center: payload.nextoreProduct.center,
      nature: payload.nextoreProduct.nature,
      subCategory: payload.nextoreProduct.subCategory,
      taxRate: payload.nextoreProduct.taxRate,
    } : {
      id: null,
      accountSale: null,
      category: null,
      center: null,
      nature: null,
      subCategory: null,
      taxRate: null,
    };

    for (const participantCategory of payload.participantCategories) {
      copyPayload.participantCategories.push({
        pricePerParticipant: parseFloat(participantCategory.pricePerParticipant / 100).toFixed(2),
        category: participantCategory.category,
        id: participantCategory['@id']
      });
    }
    copyPayload.photos = payload.mainPhoto ? [payload.mainPhoto['@id']] : [];
    copyPayload.mainPhoto = payload.mainPhoto;

    state.checkedBlockPrices.push(copyPayload);
  },
  removeFromBlockPriceCheckedList: (state, payload) => {
    state.checkedBlockPrices.splice(state.checkedBlockPrices.indexOf(payload), 1);
  },
  setImpactedParks: (state, payload) => {
    state.impactedParks = payload;
  },
  updateBlockPriceCheckedList: (state, payload) => {
    state.checkedBlockPrices.forEach((el) => {
      for (const key of Object.keys(payload)) {
        el[key] = cloneData(payload[key]);
      }
    });
  },
  setCheckedBlockPriceList: (state, list) => {
    state.checkedBlockPrices = list;
  },
  setSelectedBlockPriceModel: (state, payload) => {
    state.selectedBlockPriceModel = payload;
  },
}
