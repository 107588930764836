export default {
  getActivateCategoryList: (state) => {
    return state.activateCategoryList;
  },
  getCheckedBlockPriceCategories: (state) => {
    return state.checkedBlockPriceCategories;
  },
  getSelectedBlockPriceCategoryModel: (state) => {
    return state.selectedBlockPriceCategoryModel;
  },
}
