export default {
  getActivateCategoryList: (state) => {
    return state.activateCategoryList;
  },
  getCheckedCalendars: (state) => {
    return state.checkedCalendars;
  },
  getSelectedCalendarModel: (state) => {
    return state.selectedCalendarModel;
  },
}
