const state = {
  clubs: [],
  clubList: [],
  loaded: false,
}

// getters
const getters = {
  getClubs : (state) => { return state.clubs; },
  getClubList : (state) => { return state.clubList; },
  getIsLoaded : (state) => { return state.loaded; },
}

// mutations
const mutations = {
  setClubs: (state, payload)=>{
    state.clubs = payload;
    state.loaded = true;
  },
  setClubList: (state, payload)=>{
    state.clubList = payload;
    state.loaded = true;
  }
};

// actions
const actions = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
