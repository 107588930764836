
export default {
  addToCalendarList: (state, payload) => {
    state.checkedCalendars.push(payload);
  },
  removeFromCalendarCheckedList: (state, payload) => {
    state.checkedCalendars.splice(state.checkedCalendars.indexOf(payload), 1);
  },
  updateCalendarCheckedList: (state, payload) => {
    state.checkedCalendars.forEach((el)=> {
      for (const key of Object.keys(payload)) {
        el[key] = payload[key];
      }
    })
    ;
  },
  setCheckedCalendarList: (state, list) => {
    state.checkedCalendars = list;
  },
  setSelectedCalendarModel: (state, payload) => {
    state.selectedCalendarModel = payload;
  },
}
