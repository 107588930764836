import Vue from "vue";
import Vuex from "vuex";
import 'es6-promise/auto';
import menu from './modules/menu';
import auth from './modules/auth';
import language from './modules/language';
import layout from './modules/layout';
import currency from './modules/currency';
import club from './modules/club';
import duplicate from './modules/duplicate';
import validator from './modules/validator';
import calendar from './modules/duplicate/calendar';
import blockPriceCategory from './modules/duplicate/blockPriceCategory';
import blockPrice from './modules/duplicate/blockPrice';
import block from './modules/duplicate/block';

Vue.use(Vuex);

// const debug = process.env.NODE_ENV !== 'production'

export const store = new Vuex.Store({
  modules: {
    auth,
    currency,
    blockPrice,
    menu,
    block,
    validator,
    club,
    blockPriceCategory,
    duplicate,
    calendar,
    language,
    layout
  },
  strict: false
});

