import Vue from 'vue'
import Router from "vue-router";
import Body from '../components/core/body';
import auth from "@/router/auth";
import clubs from "@/router/clubs";
import news from "@/router/news";
import duplicate from "@/router/duplicate";
import activity from "@/router/activity";

Vue.use(Router);

const routes = [
    {
      path: '', redirect: { name: 'clubs' }
    },
    {
      path: '/',
      component: Body,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: 'dashboard',
          name: 'dashboard',
          component: () => import('@views/dashboard/Index'),
          meta: {
            title: 'Dashboard',
          }
        },
        ...clubs,
        ...news,
        ...duplicate,
        ...activity
      ]
    },
    auth
  ];
const router = new Router({
  routes,
  base: '/',
  mode: 'history',
  linkActiveClass: "active",
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});
router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/auth/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  const user = JSON.parse(loggedIn);

  if (authRequired && !loggedIn && user) {
    return next('/auth/login');
  } else if (loggedIn && !user.roles.includes('ROLE_SUPER_ADMIN') && !user.roles.includes('ROLE_ADMIN')) {
    localStorage.removeItem('user');
    localStorage.removeItem('current-club');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('selectedClub');
    localStorage.removeItem('token');
    if (from !== '/auth/login') {
      return next('/auth/login');
    }
    return false;
  }

  router.prevRoute = from;

  next();
})


export default router
