export default {
  getCheckedBlocks: (state) => {
    return state.checkedBlocks;
  },
  getImpactedParks: (state) => {
    return state.impactedParks;
  },
  getSelectedBlockModel: (state) => {
    return state.selectedBlockModel;
  },
}
