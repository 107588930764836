import httpClient from "@api/httpClient";
import Axios from "axios";
import {store} from "@/store";
import router from "@/router";
import Bus from "@/utils/bus";
import {fromIriReferenceToId} from "@/utils/form";

/*
export const clubId = () => {
    const data = localStorage.getItem('selectedClub');
    if (data) {
        return fromIriReferenceToId('/clubs/', JSON.parse(data))
    }
    return null;
};
*/

// export const clubId = localStorage.getItem('selectedClub')? fromIriReferenceToId('/clubs/', JSON.parse(localStorage.getItem('selectedClub'))) : '';

export const _get = (URL) => {
    return httpClient.get(URL)
        .then(response => {
            return new Promise((resolve) => {
               resolve(response);
            });
        })
         .catch((error) => {
             return _handleErrors(error)
                 .then((response) => {
                     return Promise.resolve(response);
                 })
                 .catch((exception) => {
                     return Promise.reject(exception);
                 })
             ;
         })
    ;
}

export const _post = (URL, data) => {
    return httpClient.post(URL, data)
        .then(response => {
            return Promise.resolve(response);
        })
         .catch((error) => {
             return _handleErrors(error)
                 .then((response) => {
                     return Promise.resolve(response);
                 })
                 .catch((exception) => {
                     return Promise.reject(exception);
                 })
             ;
         })
    ;
}

export const _put = (URL, data) => {
    return httpClient.put(URL, data)
        .then(response => {
            return Promise.resolve(response);
        })
         .catch((error) => {
             return _handleErrors(error)
                 .then((response) => {
                     return Promise.resolve(response);
                 })
                 .catch((exception) => {
                     return Promise.reject(exception);
                 })
             ;
         })
    ;
}

export const _delete = (URL) => {
    return httpClient.delete(URL)
        .then(response => {
            return Promise.resolve(response);
        })
         .catch((error) => {
             return _handleErrors(error)
                 .then((response) => {
                     return Promise.resolve(response);
                 })
                 .catch((exception) => {
                     return Promise.reject(exception);
                 })
             ;
         })
    ;
}

const _handleErrors = (error) => {
  console.error('inside _handleErrors 1', error.response.status);
    if (error.response.status !== 401 && error.response.status !== 400 && error.response.status !== 422) {
        return Promise.reject(error);
    } else if (error.response.status === 400 || error.response.status === 422) {
      Bus.$emit('on:400-errors-handled', error);
      return Promise.reject(error);
    } else {
        let reload = false;
        return _handleAuthorization(error, reload);
    }
}

const _handleAuthorization = (error, reload) => {
    return Axios
        .post(
            process.env.VUE_APP_BASE_URL + 'jwt-token/refresh',
            { refresh_token: JSON.parse(localStorage.getItem("refresh_token")) }
            )
        .then(response => {
            localStorage.setItem('refresh_token', JSON.stringify(response.data.refresh_token));

            localStorage.setItem('token', JSON.stringify(response.data.token));

            Axios.defaults.headers.common['Authorization'] = "Bearer "+ response.data.token;

            httpClient.defaults.headers.Authorization = "Bearer "+ response.data.token;

            const config = error.config;

            config.headers.Authorization = "Bearer " + response.data.token;

            return Axios.request(error.config)
                .then(response => {
                    return Promise.resolve(response);
                }).catch((error) => {
                return Promise.reject(error);
            })
            ;
        })
        .catch((error) => {
            if (error.response.status === 401 && !reload) {
                reload = true;
                store.dispatch('auth/logout');
                router.replace('/auth/login');
            } else {
                console.warn('Unresolved error => :', error);
                return Promise.reject(error);
            }
        });
}
